<template>
	<div>
		<div class="footer">
			<div class="footer_logo">
				<img src="../assets/bottom_icons/bottom_logo.png">
			</div>
			<div class="footer_main">
				<div class="footer_main_top">
					<div class="img_item">
						<img src="../assets/bottom_icons/yappy.png">
					</div>
					<div class="img_item">
						<img src="../assets/bottom_icons/star.png">
					</div>
					<div class="img_item">
						<img src="../assets/bottom_icons/clave.png">
					</div>
					<div class="img_item">
						<img src="../assets/bottom_icons/paypal.png">
					</div>
					<div class="img_item">
						<img src="../assets/bottom_icons/visa.png">
					</div>
					<div class="img_item">
						<img src="../assets/bottom_icons/master.png">
					</div>
				</div>
				<div class="footer_main_bottom">
					<div class="bottom_left">
						Copyright © 2021 por Compañia Superior, S.A. | {{$t('retainedOwnership')}} |
					</div>
					<div class="bottom_right">
						&nbsp;<span @click="agreementClick(2)">{{$t('privacyPolicy2')}}</span> | <span
							 @click="agreementClick(1)">{{$t('userRegistrationAgreement2')}}</span>
					</div>
				</div>
			</div>
			<div class="footer_download">
				<div class="footer_download_top">
					<div class="footer_left">
						<div class="qr_code">
							<vue-qr :text="qrText" :size="140" :logoSrc="logoSrc"></vue-qr>
						</div>
						<div class="qr_title">{{$t('scanCodeToDownload')}}</div>
					</div>
					<div class="footer_right">
						<img src="@/assets/home_icons/image.png">
					</div>
				</div>
				<div class="footer_download_bottom">
					<div class="img_item">
						<img src="../assets/bottom_icons/1.png">
					</div>
					<div class="img_item">
						<img src="../assets/bottom_icons/2.png">
					</div>
					<div class="img_item">
						<img src="../assets/bottom_icons/3.png">
					</div>

					<div class="img_item">
						<img src="../assets/bottom_icons/4.png">
					</div>
					<div class="img_item">
						<img src="../assets/bottom_icons/5.png">
					</div>
				</div>
			</div>
		</div>


		<el-dialog title="" :visible.sync="dialogVisible" width="38%">
			<div slot="title">
				<div class="title_all">
					<div class="title_img"><img src="@/assets/login/flag.png"></div>
					<div>{{curDialogTitle}}</div>
				</div>
			</div>
			<div class="content">
				<div v-html="content"></div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import vueQr from 'vue-qr'
	import {
		product_host
	} from '@/common/utils/config.js';


	export default {
		components: {
			vueQr
		},
		data() {
			return {
				logoSrc: 'http://www.publichino.com/uploads/images/d1/13b60ffc609e27500ae05e1d16121a.png',
				qrText: product_host + '/shareDownloadApp/index.html', //页面地址',
				dialogVisible: false, //注册协议的弹框
				curDialogTitle: '', //当前弹框标题
				content: '', //注册协议、隐私内容
				lang: '',
			}
		},
		mounted() {
			this.lang = localStorage.getItem('lang');
			window.addEventListener("setItemEvent", (e) => {
				if (e.key === "lang") {
					this.lang = e.newValue;
				}
			})
		},
		methods: {
			// 注册协议弹框
			async agreementClick(val) {
				if (val == 1) {
					this.curDialogTitle = this.$t('title.registrationProtocol');
					if (this.lang == 'zh') {
						this.category_id = 15;
					} else {
						this.category_id = 35;
					}
				} else {
					this.curDialogTitle = this.$t('title.privacyPolicy');
					if (this.lang == 'zh') {
						this.category_id = 16;
					} else {
						this.category_id = 34;
					}
				}
				await this.getArticle();
				this.dialogVisible = true;
			},
			// 获取协议
			getArticle() {
				return new Promise((resolve, reject) => {
					this.$http.getArticle({
						category_id: this.category_id,
					}).then(res => {
						if (res.code == 1) {
							this.content = res.data.content;
							resolve();
						} else {
							this.$message.error(res.msg);
						}
					})
				})
			},

		}
	}
</script>

<style scoped="" lang="less">
	.footer {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 48px 0 82px;

		.footer_logo {
			img {
				width: 165px;
				height: 165px;
			}
		}

		.footer_main {
			width: 722px;

			.footer_main_top {
				padding-bottom: 24px;
				display: flex;
				justify-content: space-around;
				// align-items: center;

				.img_item {
					img {
						width: 88px;
						height: 88px;
					}
				}
			}

			.footer_main_bottom {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 13px;
				cursor: pointer;


				.bottom_left {
					font-weight: bold;
				}

				.bottom_right {
					color: #4177DD;
				}

			}

		}

		.footer_download {
			padding-top: 24px;

			.footer_download_top {
				display: flex;
				align-items: center;

				.footer_left {
					.qr_code {
						// img {
						// 	width: 108px;
						// 	height: 108px;
						// }
					}

					.qr_title {
						margin-top: 0px;
						text-align: center;
						color: #333333;
						font-size: 14px;
					}
				}

				.footer_right {
					margin-left: 24px;
					padding-top: 16px;


					img {
						object-fit: contain;
						width: 160px;
						height: 180px;
					}

					.footer_item {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 120px;
						height: 36px;
						background-image: url(../assets/login/download_item.png);
						font-size: 14px;
						color: #E60012;
						margin-bottom: 40px;

						img {
							margin-right: 5px;
							display: flex;
							align-items: center;
							width: 13px;
							height: 16px;
						}
					}
				}
			}


			.footer_download_bottom {
				margin-top: 24px;
				display: flex;
				justify-content: space-around;

				.img_item {
					img {
						width: 44px;
						height: 44px;
					}
				}

			}
		}
	}


	.title_all {
		display: flex;
		align-items: center;
		font-size: 20px;
		font-weight: bold;

		.title_img {
			margin-right: 8px;

			img {
				display: flex;
				align-items: center;
				width: 24px;
				height: 24px;
			}
		}


	}

	.content {
		font-size: 14px;
		text-align: left;
	}

	/deep/ .el-dialog__body {
		max-height: 436px;
		overflow-y: scroll;
	}
</style>
